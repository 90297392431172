export const UNAUTHENTICATED_ROUTE_TERMINALS = {
  Login: 'login',
} as const;

export const AUTHENTICATED_ROUTE_TERMINALS = {
  ROOT: 'admin',
  NEW_ROOT: 'new-admin',
  Dealerships: {
    ROOT: 'dealerships',
    Create: 'create',
    Details: {
      ROOT: ':dealershipId',
      Overview: 'overview',
      Members: 'members',
      Policies: 'policies',
      SecurityTests: 'security-tests',
      Documents: 'documents',
      ExecutiveReports: 'executive-reports',
      RiskAssessments: 'risk-assessments',
      VendorOrganizations: 'vendor-organizations',
      VendorRequests: 'vendor-requests',
      VendorResponses: 'vendor-responses',
      AddUsers: 'add-users',
      DealershipAffiliations: 'affiliated-dealers',
    },
  },
  Documents: {
    ROOT: 'documents',
    Create: 'create',
  },
  ApplicationUsers: {
    ROOT: 'users',
    Create: 'create',
    Details: {
      ROOT: ':userId',
      Overview: 'overview',
      Dealerships: 'dealerships',
      AddDealers: 'add-dealers',
    },
  },
  NewVendors: {
    ROOT: 'vendors',
    Details: {
      ROOT: ':vendorId',
      Overview: 'overview',
      responses: 'responses',
      requests: 'requests',
      dealerships: 'dealerships',
      dealershipUploads: 'dealership-uploads',
    },
  },
  VendorRequests: {
    ROOT: 'vendor-requests',
    Details: {
      ROOT: ':vendorRequestId',
      Overview: 'overview',
      Response: 'response',
    },
  },
  RiskAssessments: {
    ROOT: 'systemRiskAssessmentDocuments',
    Show: 'show',
    Details: `:id`,
    Answers: '1',
    Risks: '2',
  },
  RiskAssessmentDocuments: {
    ROOT: 'risk-assessments',
    Details: {
      ROOT: ':riskAssessmentId',
      Overview: 'overview',
      Edit: 'edit',
      Risks: 'risks',
      Answers: 'answers',
    },
  },
  Risks: {
    ROOT: 'systemRiskAssessmentDocumentRisks',
    Show: 'show',
    Details: `:id`,
  },
  Organizations: {
    ROOT: 'systemOrganizations',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    Members: '1',
  },
  Users: {
    ROOT: 'systemUsers',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    Organization: '1',
  },
  VendorAssemsments: {
    ROOT: 'systemVendorRisks',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
    QuestionnaireInformation: '1',
    Dealerships: '2',
  },
  Vendors: {
    ROOT: 'systemVendors',
    Show: 'show',
    Details: `:id`,
    Create: 'create',
  },
  VendorRisks: {
    ROOT: 'vendor-risks',
    Details: {
      ROOT: ':vendorRiskId',
      Overview: 'overview',
      Response: 'response',
      Dealerships: 'dealerships',
    },
  },
  Tasks: {
    ROOT: 'tasks',
    Details: {
      ROOT: ':taskId',
      Overview: 'overview',
      Comments: 'comments',
    },
  },
  NewRisks: {
    ROOT: 'risks',
    Details: {
      ROOT: ':riskId',
      Overview: 'overview',
      Tasks: 'tasks',
    },
  },
  ThirdPartyApi: {
    ROOT: 'third-party-api',
    Tabs: {
      SentinelOne: {
        ROOT: 'sentinel-one',
        OperatingSystems: 'operating-systems',
      },
    },
  },
} as const;

export const ROUTES = {
  Root: `/${AUTHENTICATED_ROUTE_TERMINALS.ROOT}/*`,
  NewRoot: `/${AUTHENTICATED_ROUTE_TERMINALS.NEW_ROOT}/*`,
  Login: `/${UNAUTHENTICATED_ROUTE_TERMINALS.Login}`,
  Dealerships: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.ROOT}/*`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Create}`,
    Dealership: {
      Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Overview}`,
      Members: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Members}`,
      Policies: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Policies}`,
      SecurityTests: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.SecurityTests}`,
      Documents: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Documents}`,
      ExecutiveReports: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.ExecutiveReports}`,
      RiskAssessments: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.RiskAssessments}`,
      VendorOrganizations: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorOrganizations}`,
      VendorRequests: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorRequests}`,
      VendorResponses: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorResponses}`,
      AddUsers: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.AddUsers}`,
      DealershipAffiliations: `/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.DealershipAffiliations}`,
    },
  },
  Documents: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Documents.ROOT}/*`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Documents.Create}`,
  },
  ApplicationUsers: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.ROOT}/*`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Create}`,
    User: {
      Root: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.Overview}`,
      Dealerships: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.Dealerships}`,
      AddDealers: `/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.AddDealers}`,
    },
  },
  NewVendors: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.ROOT}/*`,
    Vendor: {
      ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.Overview}`,
      Responses: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.responses}`,
      Requests: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.requests}`,
      Dealerships: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.dealerships}`,
      DealershipUploads: `/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.dealershipUploads}`,
    },
  },
  VendorRequests: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.ROOT}/*`,
    VendorRequest: {
      ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.Details.ROOT}/*`,
      Details: {
        Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.Details.Overview}`,
        Response: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.Details.Response}`,
      },
    },
  },
  RiskAssessments: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}`,
    RiskAssessment: {
      Root: `:RiskAssessmentId/*`,
      show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}`,
        answers: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Answers}`,
        risks: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Risks}`,
      },
    },
  },
  RiskAssessmentDocuments: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.ROOT}/*`,
    RiskAssessment: {
      Root: `${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.ROOT}/*`,
      Details: {
        Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Overview}`,
        Edit: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Edit}`,
        Risks: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Risks}`,
        Answers: `/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Answers}`,
      },
    },
  },
  Organizations: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}`,
    Organization: {
      Root: `:OrganizationId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}`,
        Members: `/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Members}`,
      },
    },
  },
  Users: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}`,
    User: {
      Root: `:UserId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}`,
        Members: `/${AUTHENTICATED_ROUTE_TERMINALS.Users.Organization}`,
      },
    },
  },
  VendorAssesments: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}`,
    VendorAssessment: {
      Root: `:VendorAssessmentId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}`,
        QuestionnaireInformation: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.QuestionnaireInformation}`,
        Dealerships: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Dealerships}`,
      },
    },
  },
  Vendors: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}`,
    Create: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}`,
    Vendor: {
      Root: `:VendorId/*`,
      Show: {
        Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Show}`,
      },
    },
  },
  VendorRisks: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.ROOT}/*`,
    Details: {
      Root: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Overview}`,
      Response: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Response}`,
      Dealerships: `/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Dealerships}`,
    },
  },
  Tasks: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.ROOT}/*`,
    Task: {
      Root: `/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.Details.Overview}`,
      Comments: `/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.Details.Comments}`,
    },
  },
  Risks: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.ROOT}/*`,
    Risk: {
      Root: `/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.Details.ROOT}/*`,
      Overview: `/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.Details.Overview}`,
      Tasks: `/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.Details.Tasks}`,
    },
  },
  ThirdPartyApi: {
    Root: `/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.ROOT}/*`,
    Tabs: {
      SentinelOne: {
        ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.Tabs.SentinelOne.ROOT}/*`,
        OperatingSystems: `/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.Tabs.SentinelOne.OperatingSystems}`,
      },
    },
  },
} as const;

export const PATHS = {
  ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.ROOT}`,
  NEW_ROOT: `/${AUTHENTICATED_ROUTE_TERMINALS.NEW_ROOT}`,
  get Login() {
    return `/${UNAUTHENTICATED_ROUTE_TERMINALS.Login}` as const;
  },
  get Dealerships() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.ROOT}`,
      get Dealership() {
        return {
          ROOT: (dealershipId: string) => `${this.ROOT}/${dealershipId}` as const,
          Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Create}` as const,
          get Details() {
            return {
              ROOT: (dealershipId: string) => `${this.ROOT(dealershipId)}` as const,
              Overview: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Overview}` as const,
              Members: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Members}` as const,
              Policies: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Policies}` as const,
              SecurityTests: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.SecurityTests
                }` as const,
              Documents: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.Documents}` as const,
              ExecutiveReports: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.ExecutiveReports
                }` as const,
              RiskAssessments: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.RiskAssessments
                }` as const,
              VendorOrganizations: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorOrganizations
                }` as const,
              VendorRequests: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorRequests
                }` as const,
              VendorResponses: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.VendorResponses
                }` as const,
              AddUsers: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.AddUsers}` as const,
              DealershipAffiliations: (dealershipId: string) =>
                `${this.ROOT(dealershipId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.Dealerships.Details.DealershipAffiliations
                }` as const,
            };
          },
        };
      },
    } as const;
  },
  get Documents() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Documents.ROOT}`,
      get Document() {
        return {
          Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Documents.Create}` as const,
        };
      },
    };
  },
  get ApplicationUsers() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.ROOT}` as const,
      get User() {
        return {
          ROOT: (userId: string) => `${this.ROOT}/${userId}` as const,
          Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Create}` as const,
          get Details() {
            return {
              Overview: (userId: string) =>
                `${this.ROOT(userId)}/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.Overview}` as const,
              Dealerships: (userId: string) =>
                `${this.ROOT(userId)}/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.Dealerships}` as const,
              AddDealers: (userId: string) =>
                `${this.ROOT(userId)}/${AUTHENTICATED_ROUTE_TERMINALS.ApplicationUsers.Details.AddDealers}` as const,
            };
          },
        };
      },
    };
  },
  get NewVendors() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.ROOT}` as const,
      get Vendor() {
        return {
          ROOT: (vendorId: string) => `${this.ROOT}/${vendorId}` as const,
          get Details() {
            return {
              ROOT: (vendorId: string) => `${this.ROOT(vendorId)}` as const,
              Overview: (vendorId: string) =>
                `${this.ROOT(vendorId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.Overview}` as const,
              Responses: (vendorId: string) =>
                `${this.ROOT(vendorId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.responses}` as const,
              Requests: (vendorId: string) =>
                `${this.ROOT(vendorId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.requests}` as const,
              Dealerships: (vendorId: string) =>
                `${this.ROOT(vendorId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.dealerships}` as const,
              DealershipUploads: (vendorId: string) =>
                `${this.ROOT(vendorId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewVendors.Details.dealershipUploads}` as const,
            };
          },
        };
      },
    };
  },
  get VendorRequests() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.ROOT}` as const,
      get VendorRequest() {
        return {
          ROOT: (vendorRequestId: string) => `${this.ROOT}/${vendorRequestId}` as const,
          get Details() {
            return {
              Overview: (vendorRequestId: string) =>
                `${this.ROOT(vendorRequestId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.Details.Overview
                }` as const,
              Response: (vendorRequestId: string) =>
                `${this.ROOT(vendorRequestId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.VendorRequests.Details.Response
                }` as const,
            };
          },
        };
      },
    };
  },
  get RiskAssessments() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}`,
      RiskAssessment: {
        ROOT: (RiskAssessmentId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.ROOT}/${RiskAssessmentId}` as const,
        Show(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}` as const;
        },
        Edit(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}` as const;
        },
        Answers(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Answers
          }` as const;
        },
        Risks(RiskAssessmentId: string) {
          return `${this.ROOT(RiskAssessmentId)}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.RiskAssessments.Risks
          }` as const;
        },
      },
    };
  },
  get RiskAssessmentDocuments() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.ROOT}` as const,
      get RiskAssessment() {
        return {
          ROOT: (RiskAssessmentId: string) => `${this.ROOT}/${RiskAssessmentId}` as const,
          get Details() {
            return {
              Overview: (RiskAssessmentId: string) =>
                `${this.ROOT(RiskAssessmentId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Overview
                }` as const,
              Edit: (RiskAssessmentId: string) =>
                `${this.ROOT(RiskAssessmentId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Edit
                }` as const,
              Risks: (RiskAssessmentId: string) =>
                `${this.ROOT(RiskAssessmentId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Risks
                }` as const,
              Answers: (riskAssessmentId: string) =>
                `${this.ROOT(riskAssessmentId)}/${
                  AUTHENTICATED_ROUTE_TERMINALS.RiskAssessmentDocuments.Details.Answers
                }` as const,
            };
          },
        };
      },
    };
  },
  get Risks() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.ROOT}`,
      Risk: {
        ROOT: (RiskId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.ROOT}/${RiskId}` as const,
        Show(RiskId: string) {
          return `${this.ROOT(RiskId)}/${AUTHENTICATED_ROUTE_TERMINALS.Risks.Show}` as const;
        },
      },
    };
  },
  get Organizations() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}`,
      Organization: {
        ROOT: (OrganizationId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${OrganizationId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Create}` as const,
        Show(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}` as const;
        },
        Edit(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}` as const;
        },
        Members(OrganizationId: string) {
          return `${this.ROOT(OrganizationId)}/${AUTHENTICATED_ROUTE_TERMINALS.Organizations.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.Organizations.Members
          }` as const;
        },
      },
    };
  },
  get Users() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}`,
      User: {
        ROOT: (UserId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
        Organization(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Users.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.Users.Organization
          }` as const;
        },
      },
    };
  },
  get VendorAssessments() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}`,
      get Create() {
        return `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}`;
      },
      VendorAssessment: {
        ROOT: (UserId: string) =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
        QuestionnaireInformation(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.QuestionnaireInformation
          }` as const;
        },
        Dealership(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Show}/${
            AUTHENTICATED_ROUTE_TERMINALS.VendorAssemsments.Dealerships
          }` as const;
        },
      },
    };
  },
  get Vendors() {
    return {
      ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}`,
      Create: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}`,
      Vendor: {
        ROOT: (UserId: string) => `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${UserId}` as const,
        Create: () =>
          `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Create}` as const,
        Show(UserId: string) {
          return `${this.ROOT(UserId)}/${AUTHENTICATED_ROUTE_TERMINALS.Vendors.Show}` as const;
        },
        Edit(UserId: string) {
          return `${this.ROOT(UserId)}` as const;
        },
      },
    };
  },
  get VendorRisks() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.ROOT}` as const,
      get VendorRisk() {
        return {
          ROOT: (vendorRiskId: string) => `${this.ROOT}/${vendorRiskId}` as const,
          get Details() {
            return {
              Overview: (vendorRiskId: string) =>
                `${this.ROOT(vendorRiskId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Overview}` as const,
              Response: (vendorRiskId: string) =>
                `${this.ROOT(vendorRiskId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Response}` as const,
              Dealerships: (vendorRiskId: string) =>
                `${this.ROOT(vendorRiskId)}/${AUTHENTICATED_ROUTE_TERMINALS.VendorRisks.Details.Dealerships}` as const,
            };
          },
        };
      },
    };
  },
  get Tasks() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.ROOT}` as const,
      get Task() {
        return {
          ROOT: (taskId: string) => `${this.ROOT}/${taskId}` as const,
          get Details() {
            return {
              Overview: (taskId: string) =>
                `${this.ROOT(taskId)}/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.Details.Overview}` as const,
              Comments: (taskId: string) =>
                `${this.ROOT(taskId)}/${AUTHENTICATED_ROUTE_TERMINALS.Tasks.Details.Comments}` as const,
            };
          },
        };
      },
    };
  },
  get NewRisks() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.ROOT}` as const,
      get Risk() {
        return {
          ROOT: (riskId: string) => `${this.ROOT}/${riskId}` as const,
          get Details() {
            return {
              Overview: (riskId: string) =>
                `${this.ROOT(riskId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.Details.Overview}` as const,
              Tasks: (riskId: string) =>
                `${this.ROOT(riskId)}/${AUTHENTICATED_ROUTE_TERMINALS.NewRisks.Details.Tasks}` as const,
            };
          },
        };
      },
    };
  },
  get ThirdPartyApi() {
    return {
      ROOT: `${this.NEW_ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.ROOT}` as const,
      get Tabs() {
        return {
          SentinelOne: {
            ROOT: `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.Tabs.SentinelOne.ROOT}` as const,
            get Tabs() {
              return {
                OperatingSystems:
                  `${this.ROOT}/${AUTHENTICATED_ROUTE_TERMINALS.ThirdPartyApi.Tabs.SentinelOne.OperatingSystems}` as const,
              };
            },
          },
        };
      },
    };
  },
} as const;

export const RISK_ASSESSMENTS_HOME_PATH = PATHS.RiskAssessments.ROOT;
export const ORGANIZATION_HOME_PATH = PATHS.Organizations.ROOT;
export const DOCUMENTS_HOME_PATH = PATHS.Documents.ROOT;
export const USERS_HOME_PATH = PATHS.Users.ROOT;
export const VENDOR_ASSESSMENTS_HOME_PATH = PATHS.VendorAssessments.ROOT;
export const VENDORS_HOME_PATH = PATHS.Vendors.ROOT;
export const DEALERSHIPS_HOME_PATH = PATHS.Dealerships.ROOT;
export const APPLICATION_USERS_HOME_PATH = PATHS.ApplicationUsers.ROOT;
export const NEW_VENDORS_HOME_PATH = PATHS.NewVendors.ROOT;
export const RISK_ASSESSMENT_DOCUMENTS_HOME_PATH = PATHS.RiskAssessmentDocuments.ROOT;
export const VENDOR_REQUESTS_HOME_PATH = PATHS.VendorRequests.ROOT;
export const HOME_PATH = PATHS.NEW_ROOT;
